export function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
    }
    else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}
export async function loadIn(target, src) {
    fetch(src)
        .then((response) => {
        if (response.ok) {
            response.text().then((body) => {
                target.innerHTML = body;
            });
        }
    });
}
export function lockScroll(lock) {
    if (lock) {
        document.body.classList.add('menu-open');
    }
    else {
        document.body.classList.remove('menu-open');
    }
}
export function setLoading(loading = true, el = null) {
    if (!el) {
        el = document.querySelector('body');
        if (!el) {
            return;
        }
    }
    if (loading) {
        el.classList.add('loading');
    }
    else {
        el.classList.remove('loading');
    }
}
export function addEventListener(el, eventName, eventHandler, selector) {
    if (selector) {
        const wrappedHandler = (e) => {
            if (!e.target)
                return;
            const el = e.target.closest(selector);
            if (el) {
                const newEvent = Object.create(e, {
                    target: {
                        value: el
                    }
                });
                eventHandler.call(el, newEvent);
            }
        };
        el.addEventListener(eventName, wrappedHandler);
        return wrappedHandler;
    }
    else {
        const wrappedHandler = (e) => {
            eventHandler.call(el, e);
        };
        el.addEventListener(eventName, wrappedHandler);
        return wrappedHandler;
    }
}
